<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()"> <ion-icon slot="icon-only" name="close"></ion-icon> </ion-button>
    </ion-buttons>
    <ion-title>{{ "choose_attributes" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="bg-light">
  <div>
    <form [formGroup]="form" (ngSubmit)="submitAttributesForm()" #attributesFormRef="ngForm">
      @for (attribute of attributes; track attribute) {
        <div>
          <ion-list lines="none">
            <ion-list-header>
              <ion-label>{{attribute.DisplayText}}</ion-label>
            </ion-list-header>
            @if (readOnly) {
              <div>
                <ion-list-header>
                  <ion-label><b>Je kunt hier kiezen uit:</b></ion-label>
                </ion-list-header>
                @for (attributeValue of attribute.AttributeValues; track attributeValue) {
                  <ion-item>
                    <ion-label class="ion-text-wrap">- {{attributeValue.Name}}</ion-label>
                    @if (attributeValue.PriceAdjustment !== 0) {
                      <ion-note slot="end" color="primary">
                      +{{attributeValue.PriceAdjustment | currency:'EUR'}}</ion-note>
                    }
                  </ion-item>
                }
              </div>
            }
            @if (!readOnly) {
              <div>
                @if (attribute.ControlType === 1) {
                  <div>
                    <ion-item>
                      <ion-select slot="start" placeholder="Maak een keuze" formControlName="attribute.DisplayText">
                        @for (attributeValue of attribute.AttributeValues; track attributeValue) {
                          <ion-select-option [value]="attributeValue">
                            <span class="ion-text-wrap">{{attributeValue.Name}} </span>@if (attributeValue.PriceAdjustment !== 0) {
                            <span
                              >(+{{attributeValue.PriceAdjustment |
                            currency:'EUR'}})</span>
                          }
                        </ion-select-option>
                      }
                    </ion-select>
                  </ion-item>
                </div>
              }
              @if (attribute.ControlType === 2) {
                <div>
                  <ion-radio-group formControlName="attribute.DisplayText">
                    @for (attributeValue of attribute.AttributeValues; track attributeValue) {
                      <ion-item>
                        <ion-label class="ion-text-wrap">{{attributeValue.Name}}</ion-label>
                        <ion-radio slot="start" [value]="attributeValue"></ion-radio>
                        @if (attributeValue.PriceAdjustment !== 0) {
                          <ion-note slot="end" color="primary">
                          +{{attributeValue.PriceAdjustment | currency:'EUR'}}</ion-note>
                        }
                      </ion-item>
                    }
                  </ion-radio-group>
                </div>
              }
              @if (attribute.ControlType === 3) {
                <div>
                  @for (attributeValue of attribute.AttributeValues; track attributeValue) {
                    <ion-item>
                      <ion-label class="ion-text-wrap">{{attributeValue.Name}}</ion-label>
                      <ion-checkbox slot="start" [value]="attributeValue"
                        (ionChange)="onCheckboxAttributeChanged($event, attribute.DisplayText, attributeValue);">
                      </ion-checkbox>
                      @if (attributeValue.PriceAdjustment !== 0) {
                        <ion-note slot="end" color="primary">
                        +{{attributeValue.PriceAdjustment | currency:'EUR'}}</ion-note>
                      }
                    </ion-item>
                  }
                </div>
              }
              @if (attribute.ControlType === 11) {
                <div>
                  @for (attributeValue of attribute.AttributeValues; track attributeValue) {
                    <ion-item>
                      <ion-label>{{attributeValue.Name}}</ion-label>
                      <ion-input slot="start" class="input-number" value="0" type="tel" enterkeyhint="done" mode="ios"
                        clear-on-edit="true" maxlength="2" (keypress)="numberOnlyValidation($event)"
                        (keyup)="formatInput($event)"
                        (ionBlur)="onTextInputChanged(attribute.DisplayText, attributeValue.Name, $event.target.value, attributeValue.PriceAdjustment);">
                      </ion-input>
                      @if (attributeValue.PriceAdjustment !== 0) {
                        <ion-note slot="end" color="primary">
                        +{{attributeValue.PriceAdjustment | currency:'EUR'}}</ion-note>
                      }
                    </ion-item>
                  }
                </div>
              }
              @if (attribute.ControlType === 4 || attribute.ControlType === 5) {
                <div
                  >
                  @for (attributeValue of attribute.AttributeValues; track attributeValue) {
                    <ion-item>
                      @if (attribute.ControlType === 4) {
                        <ion-input [formControlName]="attribute.DisplayText" slot="start" fill="outline"
                        [placeholder]="attributeValue.Name" type="text" style="width: 100%;"></ion-input>
                      }
                      @if (attribute.ControlType === 5) {
                        <ion-textarea [formControlName]="attribute.DisplayText" slot="start" fill="outline"
                        [placeholder]="attributeValue.Name" rows="5" [autoGrow]="true" style="width: 100%;"></ion-textarea>
                      }
                    </ion-item>
                  }
                </div>
              }
              
            </div>
          }
        </ion-list>
      </div>
    }
  </form>
</div>
</ion-content>

@if (form) {
  <ion-footer>
    <ion-grid>
      <ion-row>
        <ion-col>
          @if (!readOnly) {
            <ion-button expand="block" shape="round" class="ion-padding-horizontal" (click)="onSubmit()"
              [disabled]="form.invalid">
            {{"add" | translate}}</ion-button>
          }
          @if (readOnly) {
            <ion-button expand="block" shape="round" class="ion-padding-horizontal" (click)="closeModal()">
            {{"close_modal" | translate}}</ion-button>
          }
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
}