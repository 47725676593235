import { ControlTypeEnum } from "../enums/controlTypeEnum";
import { ProductAttribute, ProductAttributeValue } from "../interfaces/catalog";


export class ProductAttributeModel {
    DisplayText: string;
    IsRequired: boolean;
    ControlType: ControlTypeEnum;
    DisplayOrder: number;
    MaxChoices: number;
    MaxQty: number;
    AttributeValues: ProductAttributeValueModel[] = [];

    constructor(model: ProductAttribute){
        this.DisplayText = model.DisplayText;
        this.IsRequired = model.IsRequired;
        this.ControlType = model.ControlTypeId;
        this.DisplayOrder = model.DisplayOrder;
        this.MaxChoices = model.MaxChoices;
        this.MaxQty = model.MaxQty;

        for(let pav of model.ProductAttributeValues.filter(f=> f.IsEnabled)) {
            this.AttributeValues.push(new ProductAttributeValueModel(pav));
        }
    }
}

export class ProductAttributeValueModel {
    Name: string;
    PriceAdjustment: number;
    IsDefaultSelected: boolean;
    DisplayOrder: number;
    ProductVariantImage: string;
    ColorSquaresRgb: string;
    ChosenValues: [];
    IsEnabled: boolean;

    constructor(model: ProductAttributeValue){
        this.ChosenValues = [];

        this.Name = model.Name;
        this.PriceAdjustment = model.PriceAdjustment;
        this.IsDefaultSelected = model.IsDefaultSelected;
        this.DisplayOrder = model.DisplayOrder;
        this.ProductVariantImage = model.ProductVariantImage;
        this.ColorSquaresRgb = model.ColorSquaresRgb;
        this.IsEnabled = model.IsEnabled;
    }
}

export interface NumberInputAttributeModel{
    AttributeName: string;
    AttributeValueName: string;
    Value: number;
    PriceInfluence: number;
}