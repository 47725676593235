import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular/standalone';
import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Catalog, ProductAttribute } from '../../interfaces/catalog';
import { CatalogService } from '../../services/catalog.service';
import { NumberInputAttributeModel, ProductAttributeModel, ProductAttributeValueModel } from '../../models/productAttributeModel';
import { TranslateModule } from '@ngx-translate/core';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonContent, IonList, IonListHeader, IonLabel, IonItem, IonNote, IonRadioGroup, IonRadio, IonCheckbox, IonInput, IonTextarea, IonFooter, IonGrid, IonRow, IonCol, IonSelectOption } from "@ionic/angular/standalone";
import { CurrencyPipe } from '@angular/common';

interface CheckedAttributes {
  Key: any,
  Value: any,
  PriceAdjustment: number
}

@Component({
  selector: 'page-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss'],
  standalone: true,
  providers: [CurrencyPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CurrencyPipe, ReactiveFormsModule, IonSelectOption, IonCol, IonRow, IonGrid, IonFooter, IonTextarea, IonInput, IonCheckbox, IonRadio, IonRadioGroup, IonNote, IonItem, IonLabel, IonListHeader, IonList, IonContent, IonTitle, IonIcon, IonButton, IonButtons, IonToolbar, IonHeader, TranslateModule]
})
export class AttributesComponent implements OnInit, OnDestroy {
  @Input({required: true}) productId!: number;
  @Input({required: true}) readOnly!: boolean;
  @ViewChild('attributesFormRef', { static: true }) attributesFormRef!: NgForm;

  numberInputAttributes: NumberInputAttributeModel[] = [];
  attributes: ProductAttributeModel[] = [];
  form!: FormGroup;

  private checkedAttributes: CheckedAttributes[] = [];
  private destroy$: Subject<void> = new Subject();

  constructor(private catalogService: CatalogService,
    private modalController: ModalController) { 
      this.form = new FormGroup({}); // init with empty formgroup
    }

  ngOnInit() {
    //const productId = this.router.getCurrentNavigation().extras.state.id;
    this.getProductAttributes(this.productId).pipe(first()).subscribe(productAttributes => {
      //create array of attributes
      this.attributes = productAttributes.sort((a: ProductAttributeModel, b: ProductAttributeModel) => a.DisplayOrder < b.DisplayOrder ? -1 : 1);

      //create corresponding form controls
      let group: any = {};
      for (let productAttribute of productAttributes.sort((a: ProductAttributeModel, b: ProductAttributeModel) => a.DisplayOrder < b.DisplayOrder ? -1 : 1)) {
        
        if (productAttribute.IsRequired)
        {
          group[productAttribute.DisplayText] = new FormControl(Validators.compose([Validators.required]));
        }
        else{
          group[productAttribute.DisplayText] = new FormControl();
        }
      }
      this.form = new FormGroup(group);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCheckboxAttributeChanged(event: any, key: string, value: ProductAttributeValueModel) {
    if (event.target.checked) {
      var currValue = this.form.get(key)?.value as ProductAttributeValueModel[];
      if (currValue)
        currValue.push(value);
      else {
        currValue = [];
        currValue.push(value);
      }
      this.form.get(key)?.setValue(currValue);
    }
    else {
      var currValue = this.form.get(key)?.value as ProductAttributeValueModel[];
      const filteredItems = currValue.filter(f=> f.Name !== value.Name);
      this.form.get(key)?.setValue(filteredItems);
    }
  }

  
  onTextInputChanged(key: string, attributeValueName: string, value: any, priceAdjustment: number)
  {
    if (isNaN(Number(value))) return;

    if (parseFloat(value) !== 0) {
      const currValue = this.numberInputAttributes.filter(f=> f.AttributeName === key && f.AttributeValueName === attributeValueName)[0];
      if (currValue)
      {
        currValue.Value = parseFloat(value);
        currValue.PriceInfluence = parseFloat(value) * priceAdjustment;
      }
      else {
        const numberInputAttribute: NumberInputAttributeModel = {
          AttributeName: key,
          AttributeValueName: attributeValueName,
          Value: parseFloat(value),
          PriceInfluence: parseFloat(value) * priceAdjustment
        }
        this.numberInputAttributes.push(numberInputAttribute);
      }
    }
    else {
      this.numberInputAttributes = this.numberInputAttributes.filter(f=> f.AttributeName === key && f.AttributeValueName !== attributeValueName);
    }
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  formatInput(event: any){
    if (event.target.value !== '')
    {
      event.target.value = parseFloat(event.target.value);
    }
    else{
      event.target.value = 0;
    }
  }

  onSubmit() {
    this.attributesFormRef.ngSubmit.emit();
  }

  submitAttributesForm() {
    Object.keys(this.form.controls).forEach(key => {
      const value = this.form.get(key)?.value;

      if (value &&  this.form.get(key)?.valid)
      {
        let priceAdjustment = 0;
        let finalValue = '';
  
        if (value instanceof Array)
        {
          priceAdjustment = value.reduce((sum, current) => sum + current.PriceAdjustment, 0);
          finalValue = value.map(s=> s.Name).join(', ');
        }
        else{
          if (value instanceof ProductAttributeValueModel)
          {
            priceAdjustment = value.PriceAdjustment;
            finalValue = value.Name;
          }
          else{
            finalValue = value;
          }
        }
  
        this.checkedAttributes.push({
          Key: key,
          Value: finalValue,
          PriceAdjustment: priceAdjustment
        });
      }
    });

    // traverse number input attributes
    this.numberInputAttributes.filter(f=> !isNaN(f.Value)).forEach(numberAttribute => {
      this.checkedAttributes.push({
        Key: numberAttribute.AttributeName,
        Value: `${numberAttribute.Value}x ${numberAttribute.AttributeValueName}`,
        PriceAdjustment: numberAttribute.PriceInfluence
      });
    })

    this.modalController.dismiss(this.checkedAttributes);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  private getProductAttributes(productId: number): Observable<ProductAttributeModel[]> {
      return this.catalogService.catalogUpdated$.pipe(map((catalog: Catalog)=>{
        return catalog.Products.filter(product => product.ProductId === productId)[0].Attributes
            .sort((a: ProductAttribute, b: ProductAttribute) => a.DisplayOrder < b.DisplayOrder ? -1 : 1)
            .map(attributes => new ProductAttributeModel(attributes));
      }));
  };
}
